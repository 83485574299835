import { useMemo } from 'react';

import { useRouter } from 'next/router';
import { type KeyLoader } from 'swr';
import useSWRInfinite, { type SWRInfiniteConfiguration } from 'swr/infinite';

import { NEWS_PAGE_SIZE, routes } from '@hultafors/hultaforsgroup/helpers';
import { useGlobal } from '@hultafors/hultaforsgroup/hooks';
import {
  NewsListBlockFragment,
  NewsPageListingFragment,
  NewsResponse,
} from '@hultafors/hultaforsgroup/types';

import { ArticleList } from '../article-list/article-list';
import Button from '../button/button';

interface NewsListBlockProps
  extends Omit<NewsListBlockFragment, 'id' | '__typename'> {
  fallbackData?: NewsResponse[];
}
export const NewsListBlock: React.FC<NewsListBlockProps> = ({
  title,
  fallbackData,
  largeTitle,
  standalone,
}) => {
  const { globalContent } = useGlobal();
  const router = useRouter();
  const { query } = router;
  // Creates api path that also acts as cache key for swr
  const getKey: KeyLoader = (
    index: number,
    previousPageData: NewsResponse | null,
  ) => {
    const skip = index * NEWS_PAGE_SIZE;
    if (
      previousPageData &&
      (previousPageData.meta.count < NEWS_PAGE_SIZE ||
        previousPageData.meta.count <= NEWS_PAGE_SIZE + skip)
    ) {
      return null;
    }

    const params = new URLSearchParams({
      pageSize: `${NEWS_PAGE_SIZE}`,
      skip: `${skip}`,
    });

    return `/api/news?${params.toString()}`;
  };

  // Config swr to use server side data
  const swrConfig: SWRInfiniteConfiguration<NewsResponse> = {
    fallbackData,
    initialSize: fallbackData?.length || 0,
  };

  // Initialize swr for search results fetching
  const { data, isValidating, size, setSize } = useSWRInfinite<NewsResponse>(
    getKey,
    swrConfig,
  );

  const allNewsItems: NewsPageListingFragment[] = useMemo(() => {
    return data?.flatMap((item) => item.items) || [];
  }, [data]);

  const hasMore: boolean = useMemo(() => {
    if (standalone) {
      return false;
    }
    const length = data?.flatMap((item) => item.items).length || 0;
    const dataLength = data?.length || 0;
    const count = data?.[dataLength - 1]?.meta.count || 0;
    return count && count - length > NEWS_PAGE_SIZE;
  }, [data, standalone]);

  const loadMore = () => {
    const newSize = size + 1;
    setSize(newSize);

    const params = new URLSearchParams();

    if (!newSize || newSize === 1) {
      params.delete('page');
    } else {
      params.set('page', `${newSize}`);
    }
    const query = params.toString();
    const path = router.asPath.split('?')[0];
    router.push([path, query].filter(Boolean).join('?'), undefined, {
      shallow: true,
    });
  };

  return (
    <ArticleList
      items={allNewsItems}
      title={title}
      buttonText={globalContent?.loadMoreNews}
      buttonOnClick={loadMore}
      loading={isValidating}
      showLoadMore={hasMore}
      largeTitle={largeTitle}
    >
      {standalone && (
        <Button
          to={`/${routes.news}`}
          label={globalContent?.moreNewsCta}
          center
        />
      )}
    </ArticleList>
  );
};
